import React  from 'react';

import { useLinkToApp } from '../../helper/hooks';

import Button from '../button/button';

import { yaEvent } from '../../helper/metrika';

import * as s from './burger.module.scss';

const ModalMenu = ({ handler, menuItems }) => {
  const { linkToApp } = useLinkToApp();

  return (
    <nav id="nav" className={s.modalWindow}>
      <div className={s.modalWindowContent}>
        <div className={s.modalWindowLinksContainer}>
          {
            menuItems.map((m, index) => {
              return (
                <Button
                  key={index}
                  onClick={() => {
                    if (m.onClick) {
                      m.onClick();
                    }
                    handler(false);
                  }}
                  className={s.modalWindowLink}
                  href={m.value}
                  link
                  text={m.title}
                  color={'black'}
                  noTitle
                  newWindow={m.newWindow}
                >
                <span className={s.modalWindowLinkText}>{m.title}</span>
                {m.icon && React.createElement(m.icon)}
              </Button>
              );
            })
          }
        </div>

        <div className={s.modalWindowLinksContainer}>
          <Button
            onClick={() => yaEvent('pushed_the_log_in_button')}
            className={s.modalWindowLink}
            href={linkToApp}
            link
            text="Войти"
            color={'black'}
            noTitle
          />

          <Button
            theme={'green'}
            text={'Подключить бесплатно'}
            href="https://app.sberpodbor.ru/sign-up/company"
            noTitle
            medium
            round
            newWindow
          />
        </div>
      </div>
    </nav>
  );
};

export default ModalMenu;
