import React from 'react';
import API from '../../api/';

import Button from '../button/button';

import { yaEvent } from '../../helper/metrika';
import { useLinkToApp } from '../../helper/hooks';

import * as s from './buttonsGroup.module.scss';

const ButtonGroup = ({ isScrolling }) => {
  const {
    linkToApp,
    linkToRegistration
  } = useLinkToApp();

  const onRegistrationBtnClick = () => {
    try {
      yaEvent('pushed_registration_button');
      API.metric.dispatchEvent({
        event_id: 'LANDING_CLICK_REGISTRATION'
      });
    } catch (e) {}
  };

  const onSignInBtnClick = () => {
    try {
      yaEvent('pushed_the_log_in_button');
      API.metric.dispatchEvent({
        event_id: 'LANDING_CLICK_ENTRANCE'
      });
    } catch (e) {}
  };

  return (
   <div className={s.group}>
     <Button
       className={s.button}
       onClick={onSignInBtnClick}
       text={'Вход'}
       isScroll={isScrolling}
       href={linkToApp}
       noTitle
       newWindow
       small
       link
     />
     <Button
       onClick={onRegistrationBtnClick}
       text={'Регистрация'}
       isScroll={isScrolling}
       href={linkToRegistration}
       noTitle
       newWindow
       black
       small
       round
       outline
     />
   </div>
  );
};

export default ButtonGroup;
