import React from 'react';
import cn from 'classnames';

import Button from '../button/button';

import Logo from '../../assets/icons/logo.svg';

import * as s from './footer.module.scss';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className={s.footer}>
      <div className="container">
        <div className={s.footerContent}>
          <div className={s.footerColumn}>
            <Logo
              className={s.footerLogo}
              alt="СберПодбор"
            />
            <p className={s.footerDescription}>
              СберПодбор — система, которая позволит сделать прозрачной вашу систему найма
            </p>
            <p className={s.footerExplanation}>
              *При использовании компаниями с тремя<br/> или бóльшим количеством рекрутеров в&nbsp;системе
            </p>
          </div>
          <div className={s.footerColumn}>
            <h5 className={cn(s.footerColumnTitle, s.footerColumnItem)}>
              Контакты
            </h5>
            <Button
              className={cn(s.footerColumnText, s.footerColumnItem)}
              href={'mailto:sales@sberpodbor.ru'}
              newWindow
              link
            >sales@sberpodbor.ru</Button>
            <Button
              className={cn(s.footerColumnText, s.footerColumnItem)}
              href={'tel:+74951393559'}
              newWindow
              link
            >+7 (495) 139-35-59</Button>
            <p className={cn(s.footerColumnText, s.footerColumnItem)}>
              г. Москва, Дровяной М. пер,<br/> дом 3, строение 1, помещ.II, ком.32
            </p>
          </div>
          <div className={s.footerColumn}>
            <h5 className={cn(s.footerColumnTitle, s.footerColumnItem)}>
              Важное
            </h5>
            <Button
              className={cn(s.footerColumnText, s.footerColumnItem)}
              href={'/doc/Соглашение_оферта_об_использовании_сервиса_СберПодбор.pdf'}
              newWindow
              text="Договор-оферта"
              link
            />
            <Button
              className={cn(s.footerColumnText, s.footerColumnItem)}
              href={'/doc/Политика_обработки_персональных_данных.pdf'}
              newWindow
              link
            >
              Политика обработки<br></br>персональных данных
            </Button>
            <Button
              className={cn(s.footerColumnText, s.footerColumnItem)}
              href={'/doc/Политика_информационной_безопасности_сервиса.pdf'}
              newWindow
              link
            >
              Политика информационной<br></br>безопасности
            </Button>
            <Button
              className={cn(s.footerColumnText, s.footerColumnItem)}
              href={'/doc/СберПодбор_ссылки_на_предыдущие_редакции.pdf'}
              newWindow
              link
            >
              Предыдущие редакции документов
            </Button>
          </div>
          <div className={s.footerColumn}>
            <h5 className={cn(s.footerColumnTitle, s.footerColumnItem)}>
              СберПодбор
            </h5>
            <Button
              className={cn(s.footerColumnText, s.footerColumnItem)}
              href={'/#hero'}
              text="О продукте"
              link
            />
            <Button
              className={cn(s.footerColumnText, s.footerColumnItem)}
              href={'/#reasons'}
              text="Возможности"
              link
            />
            <Button
              className={cn(s.footerColumnText, s.footerColumnItem)}
              href={'/#helps'}
              text="Плюсы"
              link
            />
            <Button
              className={cn(s.footerColumnText, s.footerColumnItem)}
              href={'/referral-program'}
              text="Реферальная программа"
              link
            />
            <Button
              className={cn(s.footerColumnText, s.footerColumnItem)}
              href={'https://blog.sberpodbor.ru/'}
              newWindow={true}
              text="Блог"
              link
            />
          </div>
        </div>
        <div className={s.footerCopyright}>
          <span>© СберПодбор {year}</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
