// extracted by mini-css-extract-plugin
export var footer = "footer-module--footer--lU7l1";
export var footerContent = "footer-module--footerContent--UOszS";
export var footerLogo = "footer-module--footerLogo--nDLxU";
export var footerDescription = "footer-module--footerDescription--uz39V";
export var footerExplanation = "footer-module--footerExplanation--Odhml";
export var footerColumn = "footer-module--footerColumn--OgPWw";
export var footerColumnItem = "footer-module--footerColumnItem--yNrUO";
export var footerColumnTitle = "footer-module--footerColumnTitle--C+R6h";
export var footerColumnText = "footer-module--footerColumnText--ge88u";
export var footerCopyright = "footer-module--footerCopyright--F10kc";