import React  from 'react';
import cn from 'classnames';

import * as s from './header.module.scss';

import Menu from '../menu/menu';
import Burger from '../burger/burger';
import ButtonGroup from '../buttons-group/buttonsGroup';

import Logo from '../../assets/icons/logo.svg';

const Header = ({ handler, isShowModal, isScroll, menuItems }) => {
  const classes = cn(
    s.header,
    isScroll && s.headerScroll
  );

  return (
    <header className={classes}>
      <div className="container">
        <div className={s.headerWrapper}>
          <Burger
            handler={handler}
            isShowModal={isShowModal}
          />
          <a
            className={s.headerLogo}
            href="/"
          >
            <Logo
              alt="СберПодбор"
            />
          </a>
          <Menu menuItems={menuItems} />
          <ButtonGroup isScrolling={isScroll} />
        </div>
      </div>
    </header>
  );
};

export default Header;
