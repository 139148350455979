import React from 'react';
import Media from 'react-media';

import Button from '../button/button';

import * as s from './menu.module.scss';

const Menu = ({ menuItems }) => {
  return (
    <nav className={s.group}>
      <Media query={'(max-width: 1024px)'} defaultMatches={true}>
        {
          matches => menuItems.filter(menuItem => !menuItem.mobile || matches).map((menuItem, index) => (
            <Button
              key={`menu_${index}`}
              href={menuItem.value}
              className={s.link}
              onClick={menuItem.onClick}
              newWindow={menuItem.newWindow}
              link
            >
              <span className={s.linkText}>{menuItem.title}</span>
              {menuItem.icon && React.createElement(menuItem.icon)}
            </Button>
          ))
        }
      </Media>
    </nav>
  );
};

export default Menu;
