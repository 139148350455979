import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { YMInitializer } from 'react-yandex-metrika';

import Header from './header/header';
import Footer from './footer/footer';
import Modal from './burger/modal';
import ModalMenu from './burger/modal-menu';

import { isProduction, isStaging } from '../helper/system';

import '../styles/main.scss';

const Layout = ({ children, isScroll, menuItems, className }) => {
  const [showModal, setShowModal] = useState(false);

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const onBurgerClick = async event => {
    setShowModal(event);
    let element = document.body.querySelector('#nav');

    if (!element) {
      element = await new Promise(resolve => {
        setTimeout(() => {
          resolve(document.body.querySelector('#nav'));
        });
      });
    }

    if (element) {

      if (event) {
        disableBodyScroll(element);
      } else {
        enableBodyScroll(element);
      }
    }
  };

  return (
    <>
      <YMInitializer
        accounts={[isProduction && !isStaging ? 64633693 : 66598438]}
      />

      <Header
        siteTitle={data.site.siteMetadata.title}
        handler={onBurgerClick}
        isShowModal={showModal}
        isScroll={isScroll}
        menuItems={menuItems}
      />
      <main className={className}>
        {children}
      </main>
      <Footer/>
      {
        showModal ? (
          <Modal>
            <ModalMenu handler={onBurgerClick} menuItems={menuItems}/>
          </Modal>
        ) : null
      }
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
