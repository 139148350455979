import React, { useEffect, useState } from 'react';
import * as s from './burger.module.scss';

const BurgerIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="14" viewBox="0 0 28 14" fill="none">
    <rect width="28" height="2" rx="1" fill="#081035"/>
    <rect y="6" width="28" height="2" rx="1" fill="#081035"/>
    <rect y="12" width="28" height="2" rx="1" fill="#081035"/>
  </svg>
);

const BackIcon = (
  <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M16.67 15.96L26.92 5.71l-.7-.71-10.26 10.25L5.71 5 5 5.7l10.25 10.26L5 26.21l.7.71 10.26-10.25 10.25 10.25.71-.7-10.25-10.26z" fill="#333F48"/>
  </svg>
);

const Burger = ({ handler, isShowModal }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(isShowModal);
  }, [isShowModal]);

  const icon = isOpen ? BackIcon : BurgerIcon;

  const onChangeHandler = () => {
    handler(!isOpen);
    setIsOpen(!isOpen);
  };

  return (
    <button
      className={s.burger}
      onClick={onChangeHandler}
    >
      { icon }
      <span>Menu</span>
    </button>
  );
};

export default Burger;
